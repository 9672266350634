<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Sent invitations</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <LoadingMessage v-if="isLoading"></LoadingMessage>

          <ErrorMessage v-if="error" :error="error"></ErrorMessage>

          <div v-if="invitations !== null" class="list-group list-group-flush">
            <div v-if="!invitations.length" class="list-group-item">No sent invitations</div>
            <div
              v-for="(invitation, invitationIndex) in invitations"
              :key="`key-${invitationIndex}`"
              class="list-group-item"
            >
              <div>{{ invitation.recipientEmail }}</div>
              <div class="small text-muted fst-italic">
                Sent {{ invitation.createdAt | dateRelative }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpGet } from '@/core/http';

export default {
  name: 'ClientSentInvitationsModal',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    async fetchInvitations() {
      this.isLoading = true;
      this.error = null;
      try {
        const res = await httpGet('/invitation/pending');
        this.invitations = res.data;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.$emit('close');
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      invitations: null,
    };
  },
  mounted() {
    this.fetchInvitations();
  },
};
</script>
